import React from "react"
import { Field, ErrorMessage } from "formik"

const FormikInput = ({
  label,
  name,
  type = "text",
  placeholder = "",
  disabled = false,
  required = false,
  helpText,
  containerClassName = "mb-2",
  labelClassName = "form-label",
  inputClassName = "",
  // Allow passing custom props to both container and input
  ...props
}) => {
  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
          {required && <span className="text-danger ms-1">*</span>}
        </label>
      )}

      <Field name={name}>
        {({ field, form: { touched, errors } }) => {
          const isInvalid = touched[name] && errors[name]
          const isValid = touched[name] && !errors[name]

          return (
            <>
              <input
                {...field}
                {...props}
                id={name}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                className={`
                  form-control
                  ${isInvalid ? "is-invalid" : ""}
                  ${isValid ? "is-valid" : ""}
                  ${inputClassName}
                `.trim()}
                aria-describedby={`${name}-feedback ${name}-help`}
              />

              {/* Error Message */}
              <ErrorMessage name={name}>
                {(msg) => (
                  <div id={`${name}-feedback`} className="invalid-feedback">
                    {msg}
                  </div>
                )}
              </ErrorMessage>

              {/* Help Text */}
              {helpText && (
                <div id={`${name}-help`} className="form-text text-muted">
                  {helpText}
                </div>
              )}
            </>
          )
        }}
      </Field>
    </div>
  )
}

export default FormikInput
