import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Pencil } from "lucide-react"
import { startCase } from "lodash"
import moment from "moment"
import { Modal, Row, Col } from "react-bootstrap"
import { useToast } from "~/utils/Toast"

import { formatCurrency } from "~/utils/helpers"
import LineItem from "~/types/LineItem"

import PageTitle from "~/library/PageTitle"
import LoadingWrapper from "~/library/LoadingWrapper"
import CatchCard from "~/library/CatchCard"
import CatchDropdown from "~/library/CatchDropdown"
import ResourceNotes from "~/library/ResourceNotes"

import LineItemModal from "./LineItemModal"

import getAppConfig from "~/utils/getAppConfig"
import useFetchResource from "~/utils/useFetchResource"
import { apiBookings } from "~/routes"

const BookingView = () => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [paymentType, setPaymentType] = useState("card")

  const [isLineItemModalOpen, setIsLineItemModalOpen] = useState(false)
  const [editingLineItem, setEditingLineItem] = useState(null)

  const { venueSlug } = getAppConfig()
  const { id } = useParams()
  const endpoint = apiBookings.show.path({ venueSlug, id })
  const { state: booking, refetch, isLoading } = useFetchResource(endpoint, "booking", {})
  const toast = useToast()

  const renderDetail = (title, content, size = 6) => {
    return (
      <div className={`col-${size || 6}`}>
        <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">{title}</p>
        <div className="d-flex">
          <div className="mt-1 font-14 mb-2">{content}</div>
        </div>
      </div>
    )
  }

  const closeLineItemModal = () => {
    setIsLineItemModalOpen(false)
    setEditingLineItem(null)
  }

  const updateLineItems = () => {
    if (editingLineItem) {
      setEditingLineItem(null)
      toast("Line Item Updated", "success")
    } else {
      toast("Line Item Created", "success")
    }
    setIsLineItemModalOpen(false)
    refetch()
  }

  const editLineItem = (lineItem) => {
    setEditingLineItem(lineItem)
    setIsLineItemModalOpen(true)
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <PageTitle title={booking.name}>
        <>
          {/*
          <a href="#" className="btn btn-primary">
            Print Booking Details
          </a>
          */}
          <CatchDropdown
            label="Actions"
            className="ms-2"
            items={[
              {
                label: "Edit Booking",
                href: "https://google.com",
              },
            ]}
          />
        </>
      </PageTitle>

      <div className="row">
        <div className="col-8">
          <CatchCard title="Booking Details">
            <div className="row">
              {renderDetail("Booking Name", booking.name, 4)}
              {renderDetail(
                "Booked Area",
                <Link to={`/areas/${booking.area?.id}`}>{booking.area?.name}</Link>,
                4
              )}
              {renderDetail("Date", moment(booking.date).format("MMM Do YYYY"), 4)}
              <div className="row mt-2">
                {renderDetail("Additional Details", booking.details || <em>No Details</em>, 12)}
              </div>
            </div>
          </CatchCard>
          <CatchCard title="Client Details">
            <div className="row">
              {renderDetail("Name", booking.clientName, 4)}
              {renderDetail("Phone", booking.clientPhone, 4)}
              {renderDetail("Email", booking.clientEmail, 4)}
            </div>
          </CatchCard>
          {isLineItemModalOpen && (
            <LineItemModal
              resourceType="booking"
              resourceId={booking.id}
              lineItem={editingLineItem}
              onClose={closeLineItemModal}
              onComplete={updateLineItems}
            />
          )}
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-middle mb-2">
                <h4 className="">Line Items</h4>
                <span
                  className="btn btn-primary ms-auto"
                  onClick={() => setIsLineItemModalOpen(true)}
                >
                  Add Line Item
                </span>
              </div>

              <div className="row">
                <table className="table table-centered mb-0">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Subtotal</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {booking?.lineItems?.map((lineItem: LineItem, i) => {
                      return (
                        <tr key={i}>
                          <td key="n">{lineItem.name}</td>
                          <td key="c">{startCase(lineItem.category)}</td>
                          <td key="p">{lineItem.quantity}</td>
                          <td key="q">{formatCurrency(lineItem.amount)}</td>
                          <td key="r">{formatCurrency(lineItem.subtotal)}</td>
                          <td key="e">
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => editLineItem(lineItem)}
                            >
                              <Pencil style={{ width: 15 }} />
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                    <tr key="total" className="bg-dark">
                      <th key="t" colSpan="5" className="text-end text-white">
                        Booking Total
                      </th>
                      <td key="ta" className="text-white">
                        {formatCurrency(booking.total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <h4 className="card-title mb-0">Payments</h4>
                <button
                  className="btn btn-primary ms-auto"
                  onClick={() => setIsPaymentModalOpen(true)}
                >
                  <i className="uil uil-plus me-2" />
                  New Payment
                </button>
              </div>
              <table className="table table-centered mb-0">
                <thead>
                  <tr>
                    <th>Payment</th>
                    <th>Created By</th>
                    <th>Paid On</th>
                    <th>Payment Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {booking.paymentList?.map((payment, i) => {
                    return (
                      <tr key={i}>
                        <td key="n">{payment.description}</td>
                        <td key="q">{payment.sentBy}</td>
                        <td key="p">{moment(payment.paidOn).format("MM/DD/YYYY")}</td>
                        <td key="r">TBD</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-4">
          <CatchCard title="Financials">
            <div className="row">
              {renderDetail("Anticipated Revenue", formatCurrency(booking.anticipatedRevenue), 12)}
              {renderDetail("Payments", "TBD", 12)}
              {renderDetail("Balance Remaining", "TBD", 12)}
            </div>
            <div className="progress">
              <div
                className="progress-bar bg-success"
                style={{ width: `${(booking.payments / booking.antRev) * 100}%` }}
              />
            </div>
          </CatchCard>
          <CatchCard title="Internal Details">
            <div className="row">
              {renderDetail("Created by", booking.createdBy?.name, 12)}
              {renderDetail(
                "Created on",
                moment(booking.createdAt).format("MMM Do YYYY hh:mma"),
                12
              )}
            </div>
          </CatchCard>
          <CatchCard title="Notes">
            <ResourceNotes resourceType="booking" resourceId={booking.id} />
          </CatchCard>
        </div>
      </div>
      <Modal show={isPaymentModalOpen} centered={true} onHide={() => setIsPaymentModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Payment</Modal.Title>
        </Modal.Header>
        <div className="p-2 text-start">
          <div className="mb-2">
            <label className="form-label d-block">Payment Type</label>
            <div className="btn-group">
              <button
                className={`btn ${paymentType === "card" ? "btn-primary" : "btn-light"}`}
                onClick={() => setPaymentType("card")}
              >
                Charge Card
              </button>
              <button
                className={`btn ${paymentType === "manual" ? "btn-primary" : "btn-light"}`}
                onClick={() => setPaymentType("manual")}
              >
                Manual Payment
              </button>
            </div>
          </div>
          {paymentType === "card" && (
            <Row>
              <Col xs="6" className="mb-2">
                <label className="form-label">Payment Amount</label>
                <div className="input-group input-group-merge">
                  <div className="input-group-text">$</div>
                  <input placeholder="00.00" className="form-control" />
                </div>
              </Col>
            </Row>
          )}
          {paymentType === "manual" && (
            <Row>
              <Col xs="6" className="mb-2">
                <label className="form-label">Payment Amount</label>
                <div className="input-group input-group-merge">
                  <div className="input-group-text">$</div>
                  <input placeholder="00.00" className="form-control" />
                </div>
              </Col>
              <Col xs="6" className="mb-2">
                <label className="form-label">Payment Date</label>
                <input type="date" placeholder="Payment Date" className="form-control" />
              </Col>
              <Col xs="6" className="mb-2">
                <label className="form-label">Payment Type</label>
                <select className="form-select" placeholder="Select Payment Type">
                  <option>Check</option>
                  <option>Cash</option>
                  <option>Credit Card</option>
                  <option>Marketing</option>
                  <option>Comp</option>
                  <option>House</option>
                </select>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs="12" className="mb-2">
              <label className="form-label">Notes</label>
              <textarea placeholder="Notes for this payment" className="form-control" />
            </Col>
            {paymentType === "card" && (
              <Col xs="12" className="mb-2">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="customCheck1" />
                  <label className="form-check-label">
                    I am authorized to charge the card on file, and have communicated with my
                    client. I assume the risk in charging this card
                  </label>
                </div>
              </Col>
            )}
          </Row>
          <div className="d-flex">
            <button className="ms-auto btn btn-link" onClick={() => setIsPaymentModalOpen(false)}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => setIsPaymentModalOpen(false)}>
              Create Payment
            </button>
          </div>
        </div>
      </Modal>
    </LoadingWrapper>
  )
}

export default BookingView
