import React from "react"
import { startCase, sortBy } from "lodash"

import getAppConfig from "~/utils/getAppConfig"
import { apiLineItems } from "~/routes"

import LineItem from "~/types/LineItem"

import CatchModal from "~/library/CatchModal"

import * as Yup from "yup"
import FormikWrapper from "~/library/FormikWrapper"
import FormikInput from "~/library/FormikInput"
import FormikSelect, { Option } from "~/library/FormikSelect"
import CurrencyInput from "~/library/CurrencyInput"

interface LineItem {
  id?: string
  name: string
  amountCents: number
  quantity: number
  category: string
}

interface LineItemModalProps {
  resourceType: string
  resourceId: string
  lineItem?: LineItem
  onClose: () => void
  onComplete: () => void
}

const lineItemSchema = Yup.object({
  name: Yup.string().required("A name is required"),
  amountCents: Yup.number().required("An amount is required"),
  category: Yup.string().required("Select a category"),
})

const LineItemModal: React.FC<LineItemModalProps> = ({
  resourceType,
  resourceId,
  lineItem,
  onClose,
  onComplete,
}) => {
  const isEdit = Boolean(lineItem?.id)
  const { venueSlug, venue } = getAppConfig()

  const initialValues: LineItem = {
    name: lineItem?.name ?? "",
    amountCents: lineItem?.amount.cents ?? 0,
    quantity: lineItem?.quantity ?? 1,
    category: lineItem?.category ?? "",
  }

  const categoryOptions = () => {
    console.log(venue.categories)
    let options: Option[] = []

    venue.categories.map((cat: string) => {
      options.push({
        label: startCase(cat),
        value: cat,
      })
    })

    options = sortBy(options, "label")

    options.push({
      label: "Other",
      value: "other",
    })

    return options
  }

  const handleSubmit = (formValues) => {
    if (lineItem?.id) {
      const req = apiLineItems.update({
        data: {
          resourceId,
          resourceType,
          venueSlug,
          id: lineItem.id,
          lineItem: formValues,
        },
      })
      req.then(onComplete)
      req.catch(({ body }) => {
        console.log(body)
      })
    } else {
      const req = apiLineItems.create({
        data: {
          resourceId,
          resourceType,
          venueSlug,
          lineItem: formValues,
        },
      })
      req.then(({ lineItem: li }: { lineItem: LineItem }) => {
        if (li.id) onComplete()
      })
      req.catch(({ body }) => {
        console.log(body)
      })
    }
  }

  //const handleSubmit = async (values: LineItem) => {
  //  try {
  //    if (isEdit && lineItem?.id) {
  //      await apiLineItems.update({
  //        venueSlug,
  //        resourceType,
  //        resourceId,
  //        lineItemId: lineItem.id,
  //        ...values
  //      })
  //    } else {
  //      await apiLineItems.create({
  //        venueSlug,
  //        resourceType,
  //        resourceId,
  //        ...values
  //      })
  //    }
  //    onClose()
  //  } catch (error) {
  //    console.error('Failed to save line item:', error)
  //  }
  //}

  return (
    <CatchModal
      isOpen={true}
      onClose={onClose}
      title={lineItem?.id ? "Update Line Item" : "New Line Item"}
    >
      <FormikWrapper
        initialValues={initialValues}
        validationSchema={lineItemSchema}
        onSubmit={handleSubmit}
      >
        <div className="space-y-4">
          <FormikInput name="name" label="Name" placeholder="Enter item name" required />
          <CurrencyInput name="amountCents" label="Amount" required />
          <FormikInput name="quantity" label="Quantity" type="number" min={1} required />

          <FormikSelect
            options={categoryOptions()}
            label="Category"
            name="category"
            placeholder="Select category"
          />

          <div className="d-flex gap-2">
            <button type="button" onClick={onClose} className="btn btn-outline-secondary ms-auto">
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              {isEdit ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </FormikWrapper>
    </CatchModal>
  )
}

export default LineItemModal
