import React from "react"
import { CheckCircle, AlertCircle, XCircle, Info, X } from "lucide-react"

// Types
type ToastType = "success" | "warning" | "danger" | "info"

interface Toast {
  id: string
  message: string
  type: ToastType
  removing?: boolean
}

// Context
const ToastContext = React.createContext<((message: string, type: ToastType) => void) | null>(null)

// Hook
export const useToast = () => {
  const context = React.useContext(ToastContext)
  if (!context) throw new Error("useToast must be used within ToastProvider")
  return context
}

// Individual Toast Component
const ToastMessage = ({
  message,
  type,
  id,
  removing,
  onDismiss,
}: Toast & { onDismiss: (id: string) => void }) => {
  const toastClasses = {
    success: "bg-success text-white",
    warning: "bg-warning text-dark",
    danger: "bg-danger text-white",
    info: "bg-info text-dark",
  }[type]

  const Icon = {
    success: CheckCircle,
    warning: AlertCircle,
    danger: XCircle,
    info: Info,
  }[type]

  return (
    <div
      className={`toast fade-out show mb-3 ${toastClasses}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{
        opacity: removing ? 0 : 1,
        transition: "all 0.5s ease-out",
      }}
    >
      <div className="toast-body">
        <div className="d-flex align-items-center">
          <Icon className="me-2" size={18} />
          <div>{message}</div>
          <div className="ms-auto">
            <span className="cursor-pointer" onClick={() => onDismiss(id)} aria-label="Close">
              <X />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

// Toast Container & Provider
export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = React.useState<Toast[]>([])

  const addToast = React.useCallback((message: string, type: ToastType) => {
    const id = Math.random().toString(36).substring(7)
    setToasts((prev) => [...prev, { id, message, type }])

    // Start removal process after 9.5 seconds
    setTimeout(() => {
      setToasts((prev) =>
        prev.map((toast) => (toast.id === id ? { ...toast, removing: true } : toast))
      )

      // Remove from DOM after animation completes
      setTimeout(() => {
        setToasts((prev) => prev.filter((toast) => toast.id !== id))
      }, 500)
    }, 9500)
  }, [])

  const dismissToast = React.useCallback((id: string) => {
    // Start removal animation
    setToasts((prev) =>
      prev.map((toast) => (toast.id === id ? { ...toast, removing: true } : toast))
    )

    // Remove from DOM after animation completes
    setTimeout(() => {
      setToasts((prev) => prev.filter((toast) => toast.id !== id))
    }, 500)
  }, [])

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div
        className="toast-container position-fixed end-0 p-3"
        style={{
          top: 50,
          transition: "all 0.5s ease-out",
        }}
      >
        {toasts.map((toast) => (
          <ToastMessage key={toast.id} {...toast} onDismiss={dismissToast} />
        ))}
      </div>
    </ToastContext.Provider>
  )
}
