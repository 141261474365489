import React, { useState, useMemo } from "react"
import { useField, useFormikContext } from "formik"
import moment from "moment"

interface DateSelectorProps {
  name?: string
  disabledDates?: (Date | string)[]
  futureOnly?: boolean
  onChange?: (date: moment.Moment) => void
  isFormik?: boolean
  value?: moment.Moment
  dateFormat?: string
  disabled?: boolean
}

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

// Helper function to chunk array into weeks
const chunkIntoWeeks = (arr: any[]) => {
  const weeks: any[][] = []
  for (let i = 0; i < arr.length; i += 7) {
    weeks.push(arr.slice(i, i + 7))
  }
  return weeks
}

// Separate Formik wrapper component
const FormikDateSelector: React.FC<DateSelectorProps> = (props) => {
  const formikContext = useFormikContext()
  const [field, meta] = useField(props.name || "")

  return <DateSelectorBase {...props} formikContext={formikContext} field={field} meta={meta} />
}

// Base component that handles the calendar logic
const DateSelectorBase: React.FC<
  DateSelectorProps & {
    formikContext?: any
    field?: any
    meta?: any
  }
> = ({
  name = "",
  disabledDates = [],
  futureOnly = false,
  onChange,
  isFormik = false,
  value: externalValue,
  dateFormat = "YYYY-MM-DD",
  disabled = false,
  formikContext,
  field,
  meta,
}) => {
  const [currentDate, setCurrentDate] = useState(() => moment().startOf("day"))
  const [internalSelectedDate, setInternalSelectedDate] = useState<moment.Moment | null>(null)

  const selectedDate = useMemo(() => {
    if (isFormik && field?.value) {
      return moment(field.value)
    }
    return externalValue || internalSelectedDate
  }, [isFormik, field?.value, externalValue, internalSelectedDate])

  const getDayClasses = (dayInfo: { disabled: boolean; selected: boolean }): string => {
    let classes = "day "

    if (dayInfo.selected && isFormik) {
      classes += "selected"
    } else if (dayInfo.disabled) {
      classes += "disabled"
    }

    return classes
  }

  const disabledDatesSet = useMemo(() => {
    const dateSet = new Set<string>()
    disabledDates.forEach((date) => {
      try {
        dateSet.add(moment(date).format(dateFormat))
      } catch (e) {
        console.warn("Invalid date provided:", date)
      }
    })
    return dateSet
  }, [disabledDates, dateFormat])

  const isDateDisabled = (date: moment.Moment): boolean => {
    const isDisabled = disabledDatesSet.has(date.format(dateFormat))
    const today = moment().startOf("day")
    const isPast = futureOnly && date.isBefore(today)

    return isDisabled || isPast
  }

  const { startingDayOfWeek, totalDays, currentMonthStr } = useMemo(() => {
    const firstDay = moment(currentDate).startOf("month")
    const lastDay = moment(currentDate).endOf("month")

    return {
      startingDayOfWeek: firstDay.day(),
      totalDays: lastDay.date(),
      currentMonthStr: `${MONTHS[currentDate.month()]} ${currentDate.year()}`,
    }
  }, [currentDate])

  const calendarDays = useMemo(() => {
    const paddingDays = Array(startingDayOfWeek).fill(null)
    const monthDays = Array.from({ length: totalDays }, (_, index) => {
      const day = index + 1
      const date = moment(currentDate).date(day)
      return {
        day,
        disabled: isDateDisabled(date) || disabled,
        selected: selectedDate && date.isSame(selectedDate, "day"),
        date,
      }
    })

    const allDays = [...paddingDays, ...monthDays]
    const remainingDays = 7 - (allDays.length % 7)
    if (remainingDays < 7) {
      allDays.push(...Array(remainingDays).fill(null))
    }

    return chunkIntoWeeks(allDays)
  }, [
    currentDate,
    totalDays,
    startingDayOfWeek,
    selectedDate,
    disabledDatesSet,
    futureOnly,
    disabled,
  ])

  const handleDateClick = (date: moment.Moment) => {
    if (disabled || isDateDisabled(date)) return

    if (isFormik && formikContext) {
      const formattedDate = date.format(dateFormat)
      formikContext.setFieldValue(name, formattedDate, true)
      formikContext.setFieldTouched(name, true, false)
    } else {
      setInternalSelectedDate(date)
      onChange?.(date)
    }
  }

  const changeMonth = (increment: number) => {
    if (disabled) return
    setCurrentDate(moment(currentDate).add(increment, "months"))
  }

  const changeYear = (increment: number) => {
    if (disabled) return
    setCurrentDate(moment(currentDate).add(increment, "years"))
  }

  return (
    <div className={`DateSelector card ${disabled ? "disabled" : ""}`}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => changeYear(-1)}
            disabled={disabled}
          >
            &lt;&lt;
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary ms-1"
            onClick={() => changeMonth(-1)}
            disabled={disabled}
          >
            &lt;
          </button>

          <h5 className="mb-0 w-100 text-center">{currentMonthStr}</h5>

          <button
            type="button"
            className="btn btn-outline-secondary me-1"
            onClick={() => changeMonth(1)}
            disabled={disabled}
          >
            &gt;
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => changeYear(1)}
            disabled={disabled}
          >
            &gt;&gt;
          </button>
        </div>

        <div className="container p-0">
          <div className="row">
            {DAYS.map((day) => (
              <div key={day} className="col p-1 text-center">
                <small className="text-muted">{day}</small>
              </div>
            ))}
          </div>

          {calendarDays.map((week, weekIndex) => (
            <div key={weekIndex} className="row">
              {week.map((dayInfo, dayIndex) => (
                <div key={dayIndex} className="col p-1 text-center">
                  {dayInfo ? (
                    <div
                      onClick={() => handleDateClick(dayInfo.date)}
                      className={getDayClasses({
                        disabled: dayInfo.disabled,
                        selected: dayInfo.selected,
                      })}
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        cursor: dayInfo.disabled ? "not-allowed" : "pointer",
                      }}
                    >
                      {dayInfo.day}
                    </div>
                  ) : (
                    <div style={{ width: "2.5rem", height: "2.5rem" }} />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>

        {isFormik && meta?.touched && meta?.error && (
          <div className="text-danger mt-2 small">{meta.error}</div>
        )}
      </div>
    </div>
  )
}

// Main component that decides which version to render
const DateSelector: React.FC<DateSelectorProps> = (props) => {
  if (props.isFormik) {
    return <FormikDateSelector {...props} />
  }
  return <DateSelectorBase {...props} />
}

export default DateSelector
