import User from "~/types/User"
import Venue from "~/types/Venue"

declare global {
  interface Window {
    cfo?: string
  }
}

interface AppConfig {
  slug: string
  user: User
  venue: Venue
}

export const getAppConfig = (): AppConfig => {
  try {
    const appElement = document.getElementById("app")
    const encodedData = appElement?.getAttribute("data-app-config")

    if (!encodedData) throw new Error("App configuration not found in app-config")

    const decodedString = atob(encodedData)
    const config = JSON.parse(decodedString) as AppConfig

    return config
  } catch (error) {
    console.error("Failed to load app configuration:", error)
    throw error
  }
}

export default getAppConfig
