import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import { map, range } from "lodash"

import { ResponsiveLine } from "@nivo/line"

import payments from "~/constants/payments"

import PageContainer from "~/library/PageContainer"

const PaymentList = () => {
  const paymentAmounts = [
    8551, 81932, 49573, 126386, 115872, 12202, 12990, 34251, 174638, 33921, 90442, 114587, 128003,
    246895, 173827, 108092, 182950, 237609, 155374, 142185, 56495, 125911, 183890, 82302, 171187,
    30220, 30393, 143405, 47188, 147431,
  ]
  const data = map(range(30), (i) => {
    return {
      x: moment().subtract(i, "days").format("YYYY-MM-DD"),
      y: paymentAmounts[i],
    }
  }).reverse()

  const graphData = () => ({
    id: "Payments",
    color: "#d35400",
    data: data,
  })

  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }

  return (
    <PageContainer>
      <div className="page-title-box d-flex align-items-center">
        <h1 className="page-title">Payments</h1>
        <div className="page-title-right m-auto me-0" />
      </div>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Past 30 Days Payments</h4>
            <div style={{ height: 250 }}>
              <ResponsiveLine
                data={[graphData()]}
                enableGridY={false}
                enableGridX={false}
                enableArea={true}
                margin={{ top: 5, right: 5, bottom: 10, left: 50 }}
                xScale={{ type: "point" }}
                yScale={{ type: "linear", min: "auto", max: "auto", stacked: true, reverse: false }}
                axisBottom={null}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Amount ($)",
                  legendOffset: -60,
                  legendPosition: "middle",
                  format: (value) => `${formatCurrency(value / 1000).replace(".00", "")}k`,
                  tickValues: 5,
                }}
                pointSize={0}
                lineWidth={3}
                curve="catmullRom"
                pointLabelYOffset={-12}
                useMesh={true}
                crosshairType="x"
                tooltip={({ point }) => (
                  <div
                    style={{
                      background: "white",
                      padding: "5px 10px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <strong>{moment(point.data.x).format("MMMM Do YYYY")}</strong>
                    <br />
                    {formatCurrency(point.data.y)}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Payments</h4>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Payment for</th>
                    <th>Payment Date</th>
                    <th>Payment Amount</th>
                    <th>Client</th>
                    <th>Charged By</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment, i) => {
                    return (
                      <tr key={i}>
                        <td key="f">
                          <Link to="/bookings/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                            {payment.target}
                          </Link>
                        </td>
                        <td key="d">{moment().subtract(i, "days").format("MM/D/YY")}</td>
                        <td key="p">{formatCurrency(payment.amount)}</td>
                        <td key="c">
                          <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                            <div className="d-flex align-items-center">
                              <img
                                src={`https://avatar.iran.liara.run/username?username=${payment.client
                                  .split(" ")
                                  .join("+")}`}
                                className="rounded-circle avatar-xs me-2"
                              />
                              {payment.client}
                            </div>
                          </Link>
                        </td>
                        <td key="cb"> {payment.createdBy}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default PaymentList
