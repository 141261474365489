// JsFromRoutes CacheKey fc7d8cfdea669b8e33fcc51b9f0eb4d5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client"

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/api/:venue_slug/:resource_type/:resource_id/line_items"
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/api/:venue_slug/:resource_type/:resource_id/line_items"
  ),
  update: /* #__PURE__ */ definePathHelper(
    "patch",
    "/api/:venue_slug/:resource_type/:resource_id/line_items/:id"
  ),
}
