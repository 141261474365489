import React from "react"

interface ColumnDef<T> {
  key: keyof T
  header: string
  formatter?: (value: any, row: T) => React.ReactNode
  width?: string
  sortable?: boolean
}

interface DataTableProps<T> {
  data: T[]
  columns: ColumnDef<T>[]
  loading?: boolean
  onSort?: (key: keyof T, direction: "asc" | "desc") => void
  sortColumn?: keyof T
  sortDirection?: "asc" | "desc"
}

function CatchTable<T>({
  data,
  columns,
  loading = false,
  onSort,
  sortColumn,
  sortDirection,
}: DataTableProps<T>) {
  const handleSort = (column: ColumnDef<T>) => {
    if (!column.sortable || !onSort) return

    const newDirection = sortColumn === column.key && sortDirection === "asc" ? "desc" : "asc"

    onSort(column.key, newDirection)
  }

  const renderSortIcon = (column: ColumnDef<T>) => {
    if (!column.sortable) return null

    if (sortColumn !== column.key) {
      return <span className="ms-1">↕️</span>
    }

    return <span className="ms-1">{sortDirection === "asc" ? "↑" : "↓"}</span>
  }

  const renderCell = (item: T, column: ColumnDef<T>) => {
    const value = item[column.key]
    if (column.formatter) {
      return column.formatter(value, item)
    }
    return value
  }

  if (loading) {
    return <div className="text-center p-3">Loading...</div>
  }

  if (!data?.length) {
    return <div className="text-center p-3">No data available</div>
  }

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap">
        <thead className="table-light">
          <tr>
            {columns.map((column) => (
              <th
                key={String(column.key)}
                style={{ width: column.width }}
                onClick={() => handleSort(column)}
                className={column.sortable ? "cursor-pointer" : ""}
              >
                {column.header}
                {renderSortIcon(column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={String(column.key)}>{renderCell(item, column)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CatchTable
