const bookingDetails = [
  {
    area: "Suite 1",
    name: "Oscar Piastri",
    bookingName: "Rookie Celebration Dinner",
    email: "oscar.piastri@example.com",
    date: "Oct 15 2024",
    antRev: 450000,
    payments: 225000,
    user: "Katie Anderson",
    details: "Celebration dinner for F1 rookie Oscar Piastri. Expecting 20 guests.",
    lineItems: [
      {
        name: "Gourmet Racing-themed Dinner",
        quantity: 20,
        category: "Food + Bev",
        price: 15000,
      },
      {
        name: "Premium Champagne Toast",
        quantity: 1,
        category: "Food + Bev",
        price: 75000,
      },
      {
        name: "F1 Memorabilia Display",
        quantity: 1,
        category: "Decor",
        price: 50000,
      },
      {
        name: "Tickets",
        quantity: 20,
        category: "Tickets",
        price: 7500,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 65000,
      },
    ],
  },
  {
    area: "Suite 2",
    name: "Philip J. Fry",
    bookingName: "Futuristic Pizza Party",
    email: "fry@planetexpress.com",
    date: "Oct 13 2024",
    antRev: 520000,
    payments: 520000,
    user: "Kyle Chaney",
    details: "Pizza party with a futuristic twist. 30 guests expected.",
    lineItems: [
      {
        name: "Custom Future Pizza Bar",
        quantity: 30,
        category: "Food + Bev",
        price: 13500,
      },
      {
        name: "Slurm Fountain",
        quantity: 1,
        category: "Food + Bev",
        price: 45000,
      },
      {
        name: "Holographic Decor Package",
        quantity: 1,
        category: "Decor",
        price: 65000,
      },
      {
        name: "Tickets",
        quantity: 30,
        category: "Tickets",
        price: 5500,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 75000,
      },
    ],
  },
  {
    area: "Suite 3",
    name: "Lando Norris",
    bookingName: "McLaren Team Lunch",
    email: "lando.norris@mclaren.com",
    date: "Oct 31 2024",
    antRev: 380000,
    payments: 190000,
    user: "Aaron Miler",
    details: "Team lunch for McLaren F1. 15 team members expected.",
    lineItems: [
      {
        name: "Gourmet Lunch Buffet",
        quantity: 15,
        category: "Food + Bev",
        price: 18500,
      },
      {
        name: "McLaren-themed Mocktails",
        quantity: 15,
        category: "Food + Bev",
        price: 12000,
      },
      {
        name: "McLaren Car Display",
        quantity: 1,
        category: "Decor",
        price: 50000,
      },
      {
        name: "Tickets",
        quantity: 15,
        category: "Tickets",
        price: 6000,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 45000,
      },
    ],
  },
  {
    area: "Suite 4",
    name: "Ron Swanson",
    bookingName: "Ron's Birthday Bash Workshop",
    email: "ron.swanson@pawnee.gov",
    date: "Oct 11 2024",
    antRev: 593700,
    payments: 412311,
    user: "Vince Surdo",
    details: "Birthday celebration with woodworking workshop. 17 people expected.",
    lineItems: [
      {
        name: "Steak Dinner per Person",
        quantity: 17,
        category: "Food + Bev",
        price: 19700,
      },
      {
        name: "Whiskey Tasting",
        quantity: 17,
        category: "Food + Bev",
        price: 12000,
      },
      {
        name: "Woodworking Supply Kits",
        quantity: 17,
        category: "Equipment",
        price: 17900,
      },
      {
        name: "Master Carpenter Fee",
        quantity: 1,
        category: "Staffing",
        price: 75000,
      },
      {
        name: "Tickets",
        quantity: 17,
        category: "Tickets",
        price: 5000,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 70000,
      },
    ],
  },
  {
    area: "Suite 5",
    name: "Max Verstappen",
    bookingName: "Dutch Grand Prix Viewing",
    email: "max.verstappen@redbullracing.com",
    date: "Oct 13 2024",
    antRev: 650000,
    payments: 325000,
    user: "Kyle Chaney",
    details: "Private viewing party for Dutch Grand Prix. 25 VIP guests.",
    lineItems: [
      {
        name: "Dutch-inspired Gourmet Snacks",
        quantity: 25,
        category: "Food + Bev",
        price: 15000,
      },
      {
        name: "Premium Open Bar",
        quantity: 1,
        category: "Food + Bev",
        price: 100000,
      },
      {
        name: "Giant LED Screens Rental",
        quantity: 2,
        category: "Equipment",
        price: 75000,
      },
      {
        name: "Tickets",
        quantity: 25,
        category: "Tickets",
        price: 8500,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 85000,
      },
    ],
  },
  {
    area: "Suite 6",
    name: "Turanga Leela",
    bookingName: "Intergalactic Meetup",
    email: "leela@planetexpress.com",
    date: "Oct 18 2024",
    antRev: 420000,
    payments: 420000,
    user: "Katie Anderson",
    details: "Meetup for intergalactic delivery captains. 20 attendees from various planets.",
    lineItems: [
      {
        name: "Multi-Species Buffet",
        quantity: 20,
        category: "Food + Bev",
        price: 17500,
      },
      {
        name: "Exotic Alien Beverage Bar",
        quantity: 1,
        category: "Food + Bev",
        price: 65000,
      },
      {
        name: "Holographic Star Map Display",
        quantity: 1,
        category: "Equipment",
        price: 55000,
      },
      {
        name: "Tickets",
        quantity: 20,
        category: "Tickets",
        price: 6500,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 60000,
      },
    ],
  },
  {
    area: "Suite 7",
    name: "Leslie Knope",
    bookingName: "Pawnee Parks Fundraiser",
    email: "leslie.knope@pawnee.gov",
    date: "Oct 11 2024",
    antRev: 687500,
    payments: 687500,
    user: "Aaron Miler",
    details: "Fundraising event for Pawnee Parks Department. 25 adults expected.",
    lineItems: [
      {
        name: "Pawnee-themed Gourmet Buffet",
        quantity: 25,
        category: "Food + Bev",
        price: 12500,
      },
      {
        name: "Waffle Bar",
        quantity: 1,
        category: "Food + Bev",
        price: 35000,
      },
      {
        name: "Custom Pawnee Parks Decorations",
        quantity: 1,
        category: "Decor",
        price: 75000,
      },
      {
        name: "Li'l Sebastian Photo Booth",
        quantity: 1,
        category: "Entertainment",
        price: 50000,
      },
      {
        name: "Tickets",
        quantity: 25,
        category: "Tickets",
        price: 6000,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 85000,
      },
    ],
  },
  {
    area: "Suite 8",
    name: "Fernando Alonso",
    bookingName: "Spanish Tapas Night",
    email: "fernando.alonso@astonmartin.com",
    date: "Oct 20 2024",
    antRev: 550000,
    payments: 275000,
    user: "Vince Surdo",
    details: "Spanish-themed evening with tapas for 30 guests.",
    lineItems: [
      {
        name: "Gourmet Tapas Station",
        quantity: 30,
        category: "Food + Bev",
        price: 15000,
      },
      {
        name: "Spanish Wine Tasting",
        quantity: 1,
        category: "Food + Bev",
        price: 80000,
      },
      {
        name: "Flamenco Dancers",
        quantity: 2,
        category: "Entertainment",
        price: 60000,
      },
      {
        name: "Tickets",
        quantity: 30,
        category: "Tickets",
        price: 7500,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 70000,
      },
    ],
  },
  {
    area: "Private Deck",
    name: "Lewis Hamilton",
    bookingName: "Champion's Cocktail Hour",
    email: "lewis.hamilton@mercedesamg.com",
    date: "Oct 22 2024",
    antRev: 750000,
    payments: 375000,
    user: "Vince Surdo",
    details: "Exclusive cocktail hour for F1 champions and VIPs. 40 attendees expected.",
    lineItems: [
      {
        name: "Luxury Canapes",
        quantity: 40,
        category: "Food + Bev",
        price: 15000,
      },
      {
        name: "Signature Cocktail Bar",
        quantity: 1,
        category: "Food + Bev",
        price: 100000,
      },
      {
        name: "DJ and Sound System",
        quantity: 1,
        category: "Entertainment",
        price: 75000,
      },
      {
        name: "Championship Trophy Display",
        quantity: 1,
        category: "Decor",
        price: 50000,
      },
      {
        name: "Tickets",
        quantity: 40,
        category: "Tickets",
        price: 8500,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 90000,
      },
    ],
  },
  {
    area: "Patio",
    name: "Charles Leclerc",
    bookingName: "Ferrari Fan Meet-up",
    email: "charles.leclerc@ferrari.com",
    date: "Oct 25 2024",
    antRev: 620000,
    payments: 620000,
    user: "Aaron Miler",
    details: "Fan meet-up and autograph session with Charles Leclerc. 50 fans expected.",
    lineItems: [
      {
        name: "Italian-inspired Buffet",
        quantity: 50,
        category: "Food + Bev",
        price: 8500,
      },
      {
        name: "Ferrari Merchandise Stand",
        quantity: 1,
        category: "Retail",
        price: 100000,
      },
      {
        name: "Ferrari F1 Car Display",
        quantity: 1,
        category: "Decor",
        price: 150000,
      },
      {
        name: "Professional Photographer",
        quantity: 1,
        category: "Staffing",
        price: 35000,
      },
      {
        name: "Tickets",
        quantity: 50,
        category: "Tickets",
        price: 5500,
      },
      {
        name: "Service Fee",
        quantity: 1,
        category: "Fees",
        price: 70000,
      },
    ],
  },
]

export default bookingDetails
