import React from "react"
import { Route, Routes, BrowserRouter } from "react-router-dom"

import WorkspaceLayout from "~/layouts/Workspace"
import Dashboard from "~/views/Dashboard"

import ClientList from "~/views/ClientList"
import ClientView from "~/views/ClientView"
import BookingList from "~/views/BookingList"
import BookingCreated from "~/views/BookingCreated"
import BookingForm from "~/views/BookingForm"
import AreaList from "~/views/AreaList"
import AreaView from "~/views/AreaView"
import PaymentList from "~/views/PaymentList"
import SalesReport from "~/views/SalesReport"
import BookingItems from "~/views/BookingItems"
import { AreaForm, BookingView } from "~/views"

import getAppConfig from "~/utils/getAppConfig"

const { venueSlug } = getAppConfig()

const Router = () => {
  return (
    <BrowserRouter basename={`workspace/${venueSlug}`}>
      <Routes>
        <Route path="/" element={<WorkspaceLayout />}>
          <Route path="/" index element={<Dashboard />} />
          <Route path="/clients" element={<ClientList />} />
          <Route path="/clients/:id" element={<ClientView />} />
          <Route path="/bookings" element={<BookingList />} />
          <Route path="/bookings/new" element={<BookingForm />} />
          <Route path="/bookings/created" element={<BookingCreated />} />
          <Route path="/bookings/:id" element={<BookingView />} />
          <Route path="/areas" element={<AreaList />} />
          <Route path="/areas/new" element={<AreaForm />} />
          <Route path="/areas/:id" element={<AreaView />} />
          <Route path="/payments" element={<PaymentList />} />
          <Route path="/insights/sales" element={<SalesReport />} />
          <Route path="/insights/bookings" element={<BookingItems />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
