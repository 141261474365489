import React from "react"
import getAppConfig from "~/utils/getAppConfig"
import { MenuSquare } from "lucide-react"

const TopNav = () => {
  const { user } = getAppConfig()
  const toggleMenu = () => {
    const ele = document.documentElement
    const currentNavSize = document.documentElement.getAttribute("data-sidenav-size")
    ele.setAttribute("data-sidenav-size", currentNavSize === "default" ? "condensed" : "default")
  }

  const search = (e) => {
    e.preventDefault()
    alert("We don't do that yet.")
  }

  return (
    <div className="navbar-custom">
      <div className="topbar container-fluid bg-white">
        <div className="d-flex align-items-center gap-lg-2 gap-1">
          <button className="button-toggle-menu" onClick={toggleMenu}>
            <MenuSquare />
          </button>
          <div className="app-search dropdown d-none d-lg-block">
            <form onSubmit={search}>
              <div className="input-group">
                <input type="search" className="form-control bg-light" placeholder="Search" />
                <span className="mdi mdi-magnify search-icon" />
                <button className="input-group-text btn btn-primary">Search</button>
              </div>
            </form>
          </div>
        </div>
        <ul className="topbar-menu d-flex align-items-center gap-3">
          <li className="nav-link fw-semibold">Hello, {user.name}</li>
          <li className="nav-link">
            <a
              href="https://app.gocatch.io"
              target="_blank"
              className="btn btn-outline-primary"
              rel="noreferrer"
            >
              Catch Mobile & POS
              <i className="mdi mdi-open-in-new ms-1" />
            </a>
          </li>
          <li className="nav-link me-4">
            <a href="/logout" className="btn btn-outline-danger">
              Log Out
              <i className="mdi mdi-logout ms-1" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TopNav
