import React from "react"
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import moment from "moment"
import { groupBy } from "lodash"

import activities from "~/constants/activity"
import pastDue from "~/constants/pastDue"

const bookingsToday = [
  {
    clientName: "Charles Leclerc",
    area: "Patio",
    salesRep: "Aaron Miler",
    bookingName: "Ferrari Fan Meet-up",
  },
  {
    clientName: "Lewis Hamilton",
    area: "Private Deck",
    salesRep: "Vince Surdo",
    bookingName: "Champion's Cocktail Hour",
  },
  {
    clientName: "Oscar Piastri",
    area: "Suite 1",
    salesRep: "Katie Anderson",
    bookingName: "Rookie Celebration Dinner",
  },
  {
    clientName: "Philip J. Fry",
    area: "Suite 2",
    salesRep: "Kyle Chaney",
    bookingName: "Futuristic Pizza Party",
  },
  {
    clientName: "Lando Norris",
    area: "Suite 3",
    salesRep: "Aaron Miler",
    bookingName: "McLaren Team Lunch",
  },
  {
    clientName: "Ron Swanson",
    area: "Suite 4",
    salesRep: "Vince Surdo",
    bookingName: "Ron's Birthday Bash Workshop",
  },
  {
    clientName: "Max Verstappen",
    area: "Suite 5",
    salesRep: "Kyle Chaney",
    bookingName: "Dutch Grand Prix Viewing",
  },
  {
    clientName: "Turanga Leela",
    area: "Suite 6",
    salesRep: "Katie Anderson",
    bookingName: "Intergalactic Meetup",
  },
  {
    clientName: "Leslie Knope",
    area: "Suite 7",
    salesRep: "Aaron Miler",
    bookingName: "Pawnee Parks Fundraiser",
  },
  {
    clientName: "Fernando Alonso",
    area: "Suite 8",
    salesRep: "Vince Surdo",
    bookingName: "Spanish Tapas Night",
  },
]
const bookingsTomorrow = [
  {
    clientName: "Ellen Ripley",
    area: "Patio",
    salesRep: "Katie Anderson",
    bookingName: "Alien Survival Seminar",
    balanceRemaining: 0,
  },
  {
    clientName: "Tony Stark",
    area: "Private Deck",
    salesRep: "Vince Surdo",
    bookingName: "Stark Industries Gala",
    balanceRemaining: 10000,
  },
  {
    clientName: "Michael Scott",
    area: "Suite 1",
    salesRep: "Aaron Miler",
    bookingName: "Dunder Mifflin Sales Pitch",
    balanceRemaining: 1500,
  },
  {
    clientName: "Dwight Schrute",
    area: "Suite 2",
    salesRep: "Katie Anderson",
    bookingName: "Beet Farmers Convention",
    balanceRemaining: 0,
  },
  {
    clientName: "Arya Stark",
    area: "Suite 3",
    salesRep: "Kyle Chaney",
    bookingName: "Swordsmanship Workshop",
    balanceRemaining: 500,
  },
  {
    clientName: "Frodo Baggins",
    area: "Suite 4",
    salesRep: "Aaron Miler",
    bookingName: "Ringbearer's Retreat",
    balanceRemaining: 2500,
  },
  {
    clientName: "Bruce Wayne",
    area: "Suite 5",
    salesRep: "Kyle Chaney",
    bookingName: "Gotham City Charity Auction",
    balanceRemaining: 8000,
  },
  {
    clientName: "Marty McFly",
    area: "Suite 6",
    salesRep: "Vince Surdo",
    bookingName: "Back to the Future Party",
    balanceRemaining: 2000,
  },
  {
    clientName: "Luke Skywalker",
    area: "Suite 7",
    salesRep: "Aaron Miler",
    bookingName: "Jedi Training",
    balanceRemaining: 0,
  },
  {
    clientName: "Sherlock Holmes",
    area: "Suite 8",
    salesRep: "Kyle Chaney",
    bookingName: "Detective Mystery Night",
    balanceRemaining: 3500,
  },
]

const groupedBookings = groupBy(bookingsTomorrow, "salesRep")

const Dashboard = () => {
  const activityIcons = {
    payment: "ri-money-dollar-circle-line",
    sale: "ri-book-line",
  }
  const formatCurrency = (currency: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }
  return (
    <div className="w-full h-full p-3">
      <h1 className="text-2xl">Hood View Park</h1>
      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-2">Today&apos;s Bookings</h4>
              <table className="table table-centered table-nowrap mb-0 table-sm">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Client Name</th>
                    <th>Area</th>
                    <th>Rep</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {bookingsToday.map((booking, i) => (
                    <tr key={i}>
                      <td>
                        <Link to={`/bookings/ea100d14-9a79-4305-90b2-638c560b4dd7`}>
                          {booking.bookingName}
                        </Link>
                      </td>
                      <td>
                        <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                          <img
                            src={`https://avatar.iran.liara.run/username?username=${booking.clientName
                              .split(" ")
                              .join("+")}`}
                            className="rounded-circle avatar-xs me-2"
                          />
                          {booking.clientName}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/area/ea100d14-9a79-4305-90b2-638c560b4dd7`}>
                          {booking.area}
                        </Link>
                      </td>
                      <td>
                        <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                          <img
                            src={`https://avatar.iran.liara.run/username?username=${booking.salesRep
                              .split(" ")
                              .join("+")}`}
                            className="rounded-circle avatar-xs me-2"
                          />
                          {booking.salesRep}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/bookings/ea100d14-9a79-4305-90b2-638c560b4dd7`}>
                          <Button variant="primary" size="sm">
                            View Booking
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Past Due & Outstanding Balances</h4>
              <table className="table table-centered table-nowrap mb-0 table-sm">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Client Name</th>
                    <th>Outstanding</th>
                    <th>Past Due</th>
                  </tr>
                </thead>
                <tbody>
                  {pastDue.map((booking, i) => {
                    const classes = []
                    if (booking.daysPastDue >= 14) {
                      classes.push("bg-danger-subtle text-white")
                    } else {
                      classes.push("bg-warning-subtle")
                    }
                    return (
                      <tr key={i} className={classes.join(" ")}>
                        <td>
                          <Link to="#">{booking.bookingName}</Link>
                        </td>
                        <td>
                          <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                            <img
                              src={`https://avatar.iran.liara.run/username?username=${booking.client
                                .split(" ")
                                .join("+")}`}
                              className="rounded-circle avatar-xs me-2"
                            />
                            {booking.client}
                          </Link>
                        </td>
                        <td>{formatCurrency(booking.balance)}</td>
                        <td className="fw-bolder">{booking.daysPastDue} days</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Tomorrow&apos;s Bookings</h4>
              {Object.keys(groupedBookings)
                .sort()
                .map((salesRep, i) => {
                  return (
                    <div className="mt-3" key={i}>
                      <h4 className="border-bottom">{salesRep}</h4>
                      {groupedBookings[salesRep].map((booking, i) => {
                        return (
                          <div key={salesRep + i}>
                            <div className="d-flex align-items-center">
                              <Link to="#">
                                <p className="m-0 fw-bold">{booking.bookingName}</p>
                              </Link>
                              <p className="mb-0 ms-auto">
                                Balance remaining: {formatCurrency(booking.balanceRemaining)}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Recent Activity</h4>
              {activities.map((activity, i) => {
                return (
                  <div className="d-flex align-items-center mb-1" key={i}>
                    <i className={activityIcons[activity.type]} />
                    <span className="ms-2">
                      {activity.text} by {activity.user}{" "}
                      {moment()
                        .subtract(i * 2.7, "hours")
                        .fromNow()}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
