import React from "react"
import { Outlet } from "react-router-dom"
import { ToastProvider } from "~/utils/Toast"

import TopNav from "./TopNav"
import Navigation from "./Navigation"

const Layout = () => {
  return (
    <ToastProvider>
      <div className="wrapper">
        <TopNav />
        <Navigation />
        <div className="content-page">
          <div className="content">
            <Outlet />
          </div>
          <div className="footer">&copy; {new Date().getFullYear()} Catch Enterprises</div>
        </div>
      </div>
    </ToastProvider>
  )
}

export default Layout
