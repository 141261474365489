import React from "react"

interface CardProps {
  children: React.ReactNode
  title?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  headerClassName?: string
  bodyClassName?: string
  titleClassName?: string
}

const CatchCard: React.FC<CardProps> = ({
  children,
  title,
  className = "",
  style,
  headerClassName = "",
  bodyClassName = "",
  titleClassName = "",
}) => {
  return (
    <div className={`card ${className}`} style={style}>
      {title && (
        <div className={`card-header pb-1 ${headerClassName}`}>
          <h5 className={`card-title mb-0 ${titleClassName}`}>{title}</h5>
        </div>
      )}
      <div className={`card-body ${title && "pt-1"} ${bodyClassName}`}>{children}</div>
    </div>
  )
}

export default CatchCard
