const clients = [
  {
    name: "Philip J. Fry",
    email: "phil@planetexpress.com",
    bookings: [1, 2, 3, 4, 5],
    lastBooking: "September 12 2024",
    totalSpend: 428455,
  },
  {
    name: "Ron Swanson",
    email: "rswanson@pawnee.gov",
    phone: "(555) 555-5555",
    lastBooking: "August 15 2024",
    totalSpend: 1578241,
    bookings: [
      {
        bookingName: "Ron's Birthday Bash",
        date: "Oct 11 2024",
        area: "Suite 4",
        payments: 412311,
      },
      {
        date: "Aug 21 2024",
        area: "Suite 4",
        payments: 193700,
        bookingName: "Ron's Steak & Whiskey Night",
      },
      {
        date: "July 4 2024",
        area: "Suite 1",
        payments: 793700,
        bookingName: "Pawnee Woodworker's Meetup",
      },
    ],
    notes: [
      {
        body: "Became a season ticket holder, seats just behind first base",
        date: "Jan 20 2024",
        author: "Vincent Surdo",
      },
      {
        body: "Does Client relations at work, started booking suites occasionally",
        date: "June 15 2024",
        author: "Vincent Surdo",
      },
    ],
  },
  {
    name: "Jim Halpert",
    email: "jim@dundermifflin.com",
    bookings: [1, 3],
    lastBooking: "August 22 2024",
    totalSpend: 782940,
  },
  {
    name: "Charlie Kelly",
    email: "charlie@paddyspub.com",
    bookings: [1, 5, 2, 3],
    lastBooking: "July 29 2024",
    totalSpend: 412600,
  },
  {
    name: "Sir Lewis Hamilton",
    email: "lewis@ferrari.com",
    bookings: [1, 2, 3],
    lastBooking: "August 2 2024",
    totalSpend: 951210,
  },
  {
    name: "Charles Leclerc",
    email: "charles@ferrari.com",
    bookings: [1, 4, 2, 1, 2, 3],
    lastBooking: "July 19 2024",
    totalSpend: 859900,
  },
  {
    name: "Lazlo Cravensworth",
    email: "lazlo@statinislandinsomniacs.com",
    bookings: [1, 2, 3],
    lastBooking: "September 5 2024",
    totalSpend: 497800,
  },
  {
    name: "Colin Robinson",
    email: "colin@beige.com",
    bookings: [1, 2, 3],
    lastBooking: "August 27 2024",
    totalSpend: 616550,
  },
  {
    name: "Bob Belcher",
    email: "bob@bobsburgers.com",
    bookings: [2, 1, 4, 1, 3],
    lastBooking: "August 11 2024",
    totalSpend: 724300,
  },
  {
    name: "Pam Beesly",
    email: "pam@dundermifflin.com",
    bookings: [1],
    lastBooking: "July 21 2024",
    totalSpend: 835670,
  },
  {
    name: "Dwight Schrute",
    email: "dwight@schrutefarms.com",
    bookings: [1, 2],
    lastBooking: "August 8 2024",
    totalSpend: 619875,
  },
  {
    name: "Michael Scott",
    email: "michael@dundermifflin.com",
    bookings: [1],
    lastBooking: "September 3 2024",
    totalSpend: 941130,
  },
  {
    name: "Leslie Knope",
    email: "leslie@pawnee.gov",
    bookings: [1, 2, 3],
    lastBooking: "August 25 2024",
    totalSpend: 865410,
  },
  {
    name: "April Ludgate",
    email: "april@pawnee.gov",
    bookings: [3],
    lastBooking: "July 16 2024",
    totalSpend: 530220,
  },
  {
    name: "Andy Dwyer",
    email: "andy@mouseRats.com",
    bookings: [1, 2, 3],
    lastBooking: "September 6 2024",
    totalSpend: 432710,
  },
  {
    name: "Walter White",
    email: "walter@lospolloshermanos.com",
    bookings: [2, 3],
    lastBooking: "August 10 2024",
    totalSpend: 758990,
  },
  {
    name: "Jesse Pinkman",
    email: "jesse@capncook.com",
    bookings: [1, 2, 3],
    lastBooking: "August 19 2024",
    totalSpend: 612450,
  },
  {
    name: "Saul Goodman",
    email: "saul@bettercallsaul.com",
    bookings: [3],
    lastBooking: "September 14 2024",
    totalSpend: 487300,
  },
]

export default clients
