import React, { useState } from "react"
import { useLocation, Link } from "react-router-dom"
import moment from "moment"
import { Modal, Row, Col } from "react-bootstrap"

import PageContainer from "~/library/PageContainer"

const BookingCreated = () => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [paymentType, setPaymentType] = useState("card")
  const [isCardModalOpen, setIsCardModalOpen] = useState(false)
  const [isCardSet, setIsCardSet] = useState(false)

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const parsedParams = {}
  for (const [key, value] of params.entries()) {
    try {
      parsedParams[key] = JSON.parse(value)
    } catch {
      parsedParams[key] = value
    }
  }

  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }

  const renderDetail = (title, content, size = 6) => {
    return (
      <div className={`col-${size || 6}`}>
        <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">{title}</p>
        <div className="d-flex">
          <div className="mt-1 font-14 mb-2">{content}</div>
        </div>
      </div>
    )
  }

  return (
    <PageContainer>
      <div className="page-title-box d-flex align-items-center">
        <h1 className="page-title">Booking</h1>
        <div className="page-title-right m-auto me-0">
          <a href="#" className="btn btn-primary">
            Print Booking Details
          </a>
          <a href="#" className="btn btn-dark dropdown-toggle ms-2">
            Actions
          </a>
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Booking Details</h4>

              <div className="row">
                {renderDetail("Booking Name", parsedParams.data.bookingName)}
                {renderDetail(
                  "Client",
                  <>
                    <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                      <div className="d-flex align-items-center">
                        <img
                          src={`https://avatar.iran.liara.run/username?username=${parsedParams.client.name
                            .split(" ")
                            .join("+")}`}
                          className="rounded-circle avatar-xs me-2"
                        />
                        {parsedParams.client.name}
                      </div>
                    </Link>
                  </>
                )}
                {renderDetail("Booked Area", <Link to="/areas/uuid">{parsedParams.area}</Link>)}
                {renderDetail("Date", moment(parsedParams.data.date).format("MMM Do YYYY"))}
                {renderDetail(
                  "Anticipated Revenue",
                  formatCurrency(parseFloat(parsedParams.data.antRev) * 100)
                )}
                {renderDetail("Payments", formatCurrency(0))}
                {renderDetail("Balance Remaining", formatCurrency(0))}
              </div>
              <div className="progress">
                <div className="progress-bar bg-success" style={{ width: 0 }} />
              </div>
              <div className="row mt-2">
                {renderDetail("Additional Details", <em>None</em>, 12)}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-middle mb-2">
                <h4 className="">Line Items</h4>
                <span className="btn btn-primary ms-auto">Add Line Item</span>
              </div>

              <div className="row">
                <table className="table table-centered mb-0">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <em>No Line Items</em>
                      </td>
                    </tr>
                    <tr key="total" className="bg-dark">
                      <th key="t" colSpan="4" className="text-end text-white">
                        Booking Total
                      </th>
                      <td key="ta" className="text-white">
                        {formatCurrency(0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <h4 className="card-title mb-0">Payments</h4>
                <button
                  className="btn btn-primary ms-auto"
                  onClick={() => setIsPaymentModalOpen(true)}
                >
                  <i className="uil uil-plus me-2" />
                  New Payment
                </button>
              </div>
              <table className="table table-centered mb-0">
                <thead>
                  <tr>
                    <th>Payment</th>
                    <th>Created By</th>
                    <th>Paid On</th>
                    <th>Payment Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={4} className="text-center">
                      <em>No Payments</em>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Card on File</h4>
              {!isCardSet && (
                <>
                  <p>
                    <em>No Card on File</em>
                  </p>
                  <button onClick={() => setIsCardModalOpen(true)} className="btn btn-primary">
                    Add Card on File
                  </button>
                </>
              )}
              {isCardSet && (
                <>
                  <h5>Card Details</h5>
                  <div className="bg-light p-2 rounded-3">
                    <div className="d-flex align-items-center">
                      <i className="ri-visa-line" style={{ fontSize: 40, lineHeight: "1em" }} />
                      <p className="m-auto me-0">**** **** **** 4242</p>
                    </div>
                    <p>
                      <strong>Ron Swanson</strong>
                    </p>
                  </div>
                  <div className="d-flex">
                    <button
                      onClick={() => setIsPaymentModalOpen(true)}
                      className="btn btn-outline-primary mt-2 ms-auto"
                    >
                      Charge Card
                    </button>
                  </div>
                </>
              )}
            </div>
            <Modal show={isCardModalOpen} centered={true} onHide={() => setIsCardModalOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Add Card on File</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <div className="mb-2">
                    <label className="form-label">Cardholder Name</label>
                    <input placeholder="John Snow" className="form-control" />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Card Number</label>
                    <input placeholder="Card Number" className="form-control" />
                  </div>
                  <Col xs="6" className="mb-2">
                    <label className="form-label">Expiration</label>
                    <input placeholder="MM/YY" className="form-control" />
                  </Col>
                  <Col xs="6" className="mb-2">
                    <label className="form-label">Billing Postal Code</label>
                    <input placeholder="12345" className="form-control" />
                  </Col>
                </Row>
                <div className="d-flex">
                  <button
                    onClick={() => setIsCardModalOpen(false)}
                    className="btn btn-link ms-auto"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      setIsCardSet(true)
                      setIsCardModalOpen(false)
                    }}
                    className="btn btn-primary"
                  >
                    Capture Card
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Internal Details</h4>
              <div className="row">
                {renderDetail("Created by", "Vincent Surdo", 12)}
                {renderDetail("Created on", moment().format("MMM Do YYYY hh:mma"), 12)}
                {renderDetail(
                  "Status",
                  <span className="badge fs-6 bg-warning">Upcoming</span>,
                  12
                )}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Notes</h4>
              <div className="text-center">
                <em>No Notes</em>
              </div>

              <label className="form-label mt-3">Add New Note</label>
              <textarea placeholder="Add new note" className="form-control" />
              <div className="d-flex">
                <button disabled className="btn btn-primary ms-auto mt-2">
                  Add Note
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={isPaymentModalOpen} centered={true} onHide={() => setIsPaymentModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Payment</Modal.Title>
        </Modal.Header>
        <div className="p-2 text-start">
          <div className="mb-2">
            <label className="form-label d-block">Payment Type</label>
            <div className="btn-group">
              <button
                className={`btn ${paymentType === "card" ? "btn-primary" : "btn-light"}`}
                onClick={() => setPaymentType("card")}
              >
                Charge Card
              </button>
              <button
                className={`btn ${paymentType === "manual" ? "btn-primary" : "btn-light"}`}
                onClick={() => setPaymentType("manual")}
              >
                Manual Payment
              </button>
            </div>
          </div>
          {paymentType === "card" && (
            <Row>
              <Col xs="6" className="mb-2">
                <label className="form-label">Payment Amount</label>
                <div className="input-group input-group-merge">
                  <div className="input-group-text">$</div>
                  <input placeholder="00.00" className="form-control" />
                </div>
              </Col>
            </Row>
          )}
          {paymentType === "manual" && (
            <Row>
              <Col xs="6" className="mb-2">
                <label className="form-label">Payment Amount</label>
                <div className="input-group input-group-merge">
                  <div className="input-group-text">$</div>
                  <input placeholder="00.00" className="form-control" />
                </div>
              </Col>
              <Col xs="6" className="mb-2">
                <label className="form-label">Payment Date</label>
                <input type="date" placeholder="Payment Date" className="form-control" />
              </Col>
              <Col xs="6" className="mb-2">
                <label className="form-label">Payment Type</label>
                <select className="form-select" placeholder="Select Payment Type">
                  <option>Check</option>
                  <option>Cash</option>
                  <option>Credit Card</option>
                  <option>Marketing</option>
                  <option>Comp</option>
                  <option>House</option>
                </select>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs="12" className="mb-2">
              <label className="form-label">Notes</label>
              <textarea placeholder="Notes for this payment" className="form-control" />
            </Col>
            {paymentType === "card" && (
              <Col xs="12" className="mb-2">
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="customCheck1" />
                  <label className="form-check-label">
                    I am authorized to charge the card on file, and have communicated with my
                    client. I assume the risk in charging this card
                  </label>
                </div>
              </Col>
            )}
          </Row>
          <div className="d-flex">
            <button className="ms-auto btn btn-link" onClick={() => setIsPaymentModalOpen(false)}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => setIsPaymentModalOpen(false)}>
              Create Payment
            </button>
          </div>
        </div>
      </Modal>
    </PageContainer>
  )
}

export default BookingCreated
