import * as React from "react"
import { Link } from "react-router-dom"

import PageTitle from "~/library/PageTitle"
import LoadingWrapper from "~/library/LoadingWrapper"
import CatchCard from "~/library/CatchCard"

import getAppConfig from "~/utils/getAppConfig"
import useFetchResource from "~/utils/useFetchResource"
import { apiAreas } from "~/routes"

const AreaList = () => {
  const { venueSlug } = getAppConfig()
  const endpoint = apiAreas.index.path({ venueSlug })
  const { state: areas, isLoading } = useFetchResource(endpoint, "areas", {})

  return (
    <LoadingWrapper isLoading={isLoading}>
      <PageTitle title="Bookable Areas" newItemPath="/areas/new" />

      <div className="row">
        {areas?.map((area) => {
          return (
            <div className="col-4" key={area.id}>
              <CatchCard>
                <div className="dropdown card-widgets">
                  <a
                    href="#"
                    className="dropdown-toggle arrow-none"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="ri-more-fill" />
                  </a>
                </div>
                <h4 className="text-title">
                  <Link to={`/areas/${area.id}`}>{area.name}</Link>
                </h4>
                <div className="d-flex fs-5">
                  <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Capacity">
                    <i className="uil uil-user" /> Capacity {area.capacity}
                  </div>
                  <div className="ms-2">
                    <i className="uil uil-calender" /> Bookings {area.bookingCount}
                  </div>
                </div>
              </CatchCard>
            </div>
          )
        })}
      </div>
    </LoadingWrapper>
  )
}

export default AreaList
