import React from "react"
import { Loader2 } from "lucide-react"

const LoadingWrapper = ({ isLoading = false, children }) => {
  if (isLoading) {
    return (
      <div className="d-flex container-fluid justify-content-center align-items-center w-100 h-100 p-4">
        <Loader2 className="spinner" style={{ width: "48px", height: "48px" }} />
      </div>
    )
  }

  return <div className="container-fluid">{children}</div>
}

export default LoadingWrapper
