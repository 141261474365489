import React from "react"
import { Field, FieldProps } from "formik"
import { Form, InputGroup } from "react-bootstrap"

interface CurrencyInputProps {
  name: string
  label?: string
  currency: "USD" | "CAD"
  placeholder?: string
  disabled?: boolean
}

const CurrencyInputComponent: React.FC<CurrencyInputProps & FieldProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  currency = "USD",
  placeholder = "0.00",
  disabled = false,
}) => {
  const currencySymbol = currency === "USD" ? "$" : "CA$"

  // Format cents to decimal string for display (without currency symbol)
  const formatAmount = (cents: number): string => {
    if (!cents && cents !== 0) return ""

    // Use Number format to add commas and fixed decimal places
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cents / 100)
  }

  // Convert input string to cents
  const parseCurrency = (value: string): number => {
    // Remove all non-numeric characters except decimal point
    const cleanValue = value.replace(/[^0-9.]/g, "")

    if (cleanValue === "") return 0

    // If there are multiple decimal points, keep only the first one
    const parts = cleanValue.split(".")

    if (parts.length === 1) {
      // No decimal point - treat as whole number of cents
      return Math.round(Number(parts[0]))
    }

    // Combine all numbers after decimal point
    const wholePart = parts[0] || "0"
    const decimalPart = parts.slice(1).join("")

    // If there's content after the decimal, treat it as additional whole numbers
    if (decimalPart) {
      const combinedNumber = wholePart + decimalPart
      return Math.round(Number(combinedNumber))
    }

    // Handle case where input ends with decimal point
    return Math.round(Number(wholePart) * 100)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    // Allow empty input
    if (inputValue === "") {
      setFieldValue(field.name, 0)
      return
    }

    // Clean and validate the input
    const numericValue = inputValue.replace(/[^0-9.]/g, "")
    const cents = parseCurrency(numericValue)
    setFieldValue(field.name, cents)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e)
    if (field.value || field.value === 0) {
      const formatted = formatAmount(field.value)
      e.target.value = formatted
    }
  }

  // Handle paste events to clean input
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData("text")
    const cleanValue = pastedText.replace(/[^0-9.]/g, "")
    const cents = parseCurrency(cleanValue)
    setFieldValue(field.name, cents)
  }

  const error = touched[field.name] && errors[field.name]

  return (
    <Form.Group className="mb-2">
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup>
        <InputGroup.Text>{currencySymbol}</InputGroup.Text>
        <Form.Control
          type="text"
          name={field.name}
          inputMode="decimal"
          placeholder={placeholder}
          disabled={disabled}
          value={formatAmount(field.value)}
          onChange={handleChange}
          onBlur={handleBlur}
          onPaste={handlePaste}
          isInvalid={!!error}
        />
        {error && <Form.Control.Feedback type="invalid">{String(error)}</Form.Control.Feedback>}
      </InputGroup>
    </Form.Group>
  )
}

const CurrencyInput: React.FC<CurrencyInputProps> = (props) => (
  <Field name={props.name} component={CurrencyInputComponent} {...props} />
)

export default CurrencyInput
