// JsFromRoutes CacheKey e2c511e43d7881b07290273e37dfc866
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client"

export default {
  index: /* #__PURE__ */ definePathHelper(
    "get",
    "/api/:venue_slug/:resource_type/:resource_id/notes"
  ),
  create: /* #__PURE__ */ definePathHelper(
    "post",
    "/api/:venue_slug/:resource_type/:resource_id/notes"
  ),
}
