import React from "react"
import { useField, useFormikContext } from "formik"
import PropTypes from "prop-types"

export interface Option {
  value: string
  label: string
}

const FormikSelect = ({
  name,
  label,
  options,
  disabled = false,
  className = "",
  placeholder = "Select an option",
  required = false,
  helpText,
  ...props
}) => {
  const [field, meta] = useField(name)
  const { setFieldValue, setFieldTouched } = useFormikContext()

  const handleChange = (event) => {
    setFieldValue(name, event.target.value)
  }

  const handleBlur = () => {
    setFieldTouched(name, true)
  }

  const isInvalid = meta.touched && meta.error
  const isValid = meta.touched && !meta.error

  return (
    <div className="form-group mb-3">
      {label && (
        <label htmlFor={name} className="form-label">
          {label}
          {required && <span className="text-danger ms-1">*</span>}
        </label>
      )}

      <select
        {...field}
        {...props}
        id={name}
        className={`form-select ${className} ${isInvalid ? "is-invalid" : ""} ${
          isValid ? "is-valid" : ""
        }`}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      >
        <option value="">{placeholder}</option>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>

      {helpText && <div className="form-text text-muted small">{helpText}</div>}

      {isInvalid && <div className="invalid-feedback">{meta.error}</div>}
    </div>
  )
}

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  helpText: PropTypes.string,
}

export default FormikSelect
