const payments = [
  {
    createdBy: "Vincent Surdo",
    amount: 105435,
    target: "Suite 3 - Oct 17th 2024",
    client: "Mordocai Rigby",
  },
  {
    createdBy: "Vincent Surdo",
    amount: 207108,
    target: "Suite 7 - Aug 11th 2024",
    client: "Sir Lewis Hamilton",
  },
  {
    createdBy: "Scott Budraux",
    amount: 175065,
    target: "Sponsor Invoice - 2025 Left Field",
    client: "Charlie Kelly",
  },
  {
    createdBy: "Vincent Surdo",
    amount: 468763,
    target: "Private Deck - Oct 7th 2024",
    client: "Charlie Kelly",
  },
  {
    createdBy: "Michael Kveton",
    amount: 248864,
    target: "Patio - Sep 18th 2024",
    client: "Ron Swanson",
  },
  {
    createdBy: "Vincent Surdo",
    amount: 920950,
    target: "Private Deck - Sep 7th 2024",
    client: "William Adama",
  },
  {
    createdBy: "Scott Budraux",
    amount: 228638,
    target: "Suite 1 - Sep 27th 2024",
    client: "Charles Leclerc",
  },
  {
    createdBy: "Scott Budraux",
    amount: 137585,
    target: "Suite 2 - Aug 21st 2024",
    client: "Philip J. Fry",
  },
  {
    createdBy: "Scott Budraux",
    amount: 235618,
    target: "Private - Deck Jul 20th 2024",
    client: "William Adama",
  },
  {
    createdBy: "Aaron Miler",
    amount: 160023,
    target: "Patio - May 11th 2025",
    client: "Aegon Targaryen",
  },
  {
    createdBy: "Vincent Surdo",
    amount: 907214,
    target: "Private Deck - Jun 23rd 2024",
    client: "William Adama",
  },
  {
    createdBy: "Vincent Surdo",
    amount: 208575,
    target: "Private Deck Sep 18th 2024",
    client: "Charlie Kelly",
  },
  {
    createdBy: "Vincent Surdo",
    amount: 208898,
    target: "Private Deck - April 23rd 2025",
    client: "Finn Mertains",
  },
  {
    createdBy: "Michael Kveton",
    amount: 538303,
    target: "Patio - Aug 19th 2024",
    client: "Dennis Reynolds",
  },
  {
    createdBy: "Vincent Surdo",
    amount: 136421,
    target: "Suite 7 - Sep 9th 2024",
    client: "Mordocai Rigby",
  },
]

export default payments
