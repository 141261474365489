import * as React from "react"
import { Link } from "react-router-dom"
import moment from "moment"

import clients from "~/constants/clients"

import PageContainer from "~/library/PageContainer"

const ClientList = () => {
  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }

  return (
    <PageContainer>
      <div className="page-title-box">
        <h1 className="page-title">Clients</h1>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Client</th>
                      <th>Client Email</th>
                      <th>Bookings</th>
                      <th>Last Booking</th>
                      <th>Total Spend</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients.map((client, i) => {
                      return (
                        <tr key={i}>
                          <td key="n" className="align-middle">
                            <div className="d-flex align-items-center">
                              <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                                <img
                                  src={`https://avatar.iran.liara.run/username?username=${client.name
                                    .split(" ")
                                    .join("+")}`}
                                  className="rounded-circle avatar-xs me-2"
                                />
                                {client.name}
                              </Link>
                            </div>
                          </td>
                          <td key="e">{client.email}</td>
                          <td key="b">{client.bookings.length}</td>
                          <td key="l">{moment(client.lastBooking).format("MMM Do YYYY")}</td>
                          <td key="t">{formatCurrency(client.totalSpend)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default ClientList
