import React, { useEffect, createContext, useContext } from "react"
import { Formik, Form, Field } from "formik"

const FormContext = createContext()

export const useFormValues = () => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error("useFormValues must be used within a FormikWrapper")
  }
  return context
}

const FormikWrapper = ({
  initialValues = {},
  validationSchema,
  onSubmit,
  onValuesChange,
  children,
}) => {
  // Updated helper to properly identify form controls
  const isFormControl = (element) => {
    // Check if it's a direct form element
    const formControlElements = ["input", "select", "textarea"]
    const elementType = element.type?.toLowerCase?.() || ""

    // Check if it's a Formik Field or our custom components
    const isFormikField = element.type === Field
    const isCustomFormComponent =
      element.type.name === "FormInput" || element.type.name === "FormikTextarea"

    // Check if it has a name prop (required for Formik)
    const hasNameProp = !!element.props?.name

    return (
      (formControlElements.includes(elementType) || isFormikField || isCustomFormComponent) &&
      hasNameProp
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={false}
      validateOnChange={true}
      onSubmit={async (values, helpers) => {
        try {
          await onSubmit(values, helpers)
        } catch (error) {
          helpers.setErrors({ submit: error.message })
        } finally {
          helpers.setSubmitting(false)
        }
      }}
    >
      {(formikProps) => {
        const { values, errors, touched, isSubmitting } = formikProps

        return (
          <FormContext.Provider value={formikProps}>
            <Form className="mb-3">
              {errors.submit && (
                <div className="alert alert-danger" role="alert">
                  {errors.submit}
                </div>
              )}

              {React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) return child

                const name = child.props.name
                if (!name) return child

                const error = touched[name] && errors[name]

                // Only apply form-control class to actual form controls
                const shouldAddFormControl = isFormControl(child)
                const className = shouldAddFormControl
                  ? `form-control ${error ? "is-invalid" : touched[name] ? "is-valid" : ""} ${
                      child.props.className || ""
                    }`
                  : child.props.className

                return React.cloneElement(child, {
                  error,
                  touched: touched[name],
                  disabled: isSubmitting,
                  className,
                })
              })}

              <FormValuesWatcher values={values} onChange={onValuesChange} />
            </Form>
          </FormContext.Provider>
        )
      }}
    </Formik>
  )
}

const FormValuesWatcher = ({ values, onChange }) => {
  useEffect(() => {
    if (onChange) {
      onChange(values)
    }
  }, [values, onChange])

  return null
}

export default FormikWrapper
