import moment from "moment"

interface CurrencyInput {
  cents: number
  currency_iso: string
}

type DateFormat = "default" | "short" | "long" | "iso"

const DATE_FORMATS = {
  default: "MMM Do YYYY", // Oct 31st 2024
  short: "MM/DD/YY", // 10/31/24
  long: "MMMM Do, YYYY", // October 31st, 2024
  iso: "YYYY-MM-DD", // 2024-10-31
}

/**
 * Formats a currency amount from cents to a localized string
 * @param {CurrencyInput} input - Object containing cents and currency_iso
 * @returns {string} Formatted currency string
 * @example
 * formatCurrency({ cents: 1234567890, currency_iso: "USD" })
 * // Returns "$12,345,678.90"
 */
export const formatCurrency = (input?: CurrencyInput | null): string => {
  if (!input) return ""
  const { cents, currency_iso = "USD" } = input

  const amount = cents / 100

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency_iso,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
}

/**
 * Formats a date string or object to a consistent format
 * @param {string | Date} date - Date to format
 * @param {DateFormat} [format="default"] - Predefined format to use
 * @returns {string} Formatted date string
 * @example
 * formatDate("2024-10-31")
 * // Returns "Oct 31st 2024"
 */
export const formatDate = (date: string | Date, format: DateFormat = "default"): string => {
  const momentDate = moment(date)

  if (!momentDate.isValid()) {
    throw new Error("Invalid date provided")
  }

  return momentDate.format(DATE_FORMATS[format])
}
