import React from "react"
import { Link } from "react-router-dom"

import bookingDetails from "~/constants/bookingDetails"

import PageContainer from "~/library/PageContainer"

const BookingItems = () => {
  return (
    <PageContainer>
      <div className="page-title-box d-flex align-items-center">
        <h1 className="page-title">Booking Service Details</h1>
        <div className="page-title-right m-auto me-0">
          <select className="form-select">
            <option>Today</option>
            <option>Next 7 Days</option>
            <option>Next 14 Days</option>
            <option>Custom Range</option>
          </select>
        </div>
      </div>

      <div className="row">
        {bookingDetails.map((booking, i) => {
          return (
            <div key={i} className="col-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <Link
                        to="/bookings/7cf331b3-3ee5-4b86-8b40-253de1e1a813"
                        className="ms-auto"
                        target="_blank"
                      >
                        <h3>{booking.bookingName}</h3>
                      </Link>
                    </div>
                    <Link
                      to="/bookings/7cf331b3-3ee5-4b86-8b40-253de1e1a813"
                      className="ms-auto"
                      target="_blank"
                    >
                      <i className="uil uil-export ms-1" />
                    </Link>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h5>Area</h5>
                      <h4>{booking.area}</h4>
                    </div>
                    <div className="col-3">
                      <h5>Date</h5>
                      <h4>{booking.date}</h4>
                    </div>
                    <div className="col-3">
                      <h5>Client</h5>
                      <h4>
                        <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                          <span className="d-inline-flex align-items-center">
                            <img
                              src={`https://avatar.iran.liara.run/username?username=${booking.name
                                .split(" ")
                                .join("+")}`}
                              className="rounded-circle avatar-xs me-2"
                            />
                            {booking.name}
                          </span>
                        </Link>
                      </h4>
                    </div>
                    <div className="col-3">
                      <h5>Sales Rep</h5>
                      <h4>
                        <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                          <span className="d-inline-flex align-items-center">
                            <img
                              src={`https://avatar.iran.liara.run/username?username=${booking.user
                                .split(" ")
                                .join("+")}`}
                              className="rounded-circle avatar-xs me-2"
                            />
                            {booking.user}
                          </span>
                        </Link>
                      </h4>
                    </div>
                    <div>
                      <label className="form-label">Details</label>
                      <p>{booking.details}</p>
                    </div>
                    <table className="table table-sm table-centered mb-0">
                      <thead>
                        <tr>
                          <th>Quantity</th>
                          <th>Title</th>
                          <th>Category</th>
                        </tr>
                      </thead>
                      <tbody>
                        {booking.lineItems.map((lineItem, i) => {
                          return (
                            <tr key={i}>
                              <td key="p">{lineItem.quantity}</td>
                              <td key="n">{lineItem.name}</td>
                              <td key="c">{lineItem.category}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </PageContainer>
  )
}
export default BookingItems
