import React from "react"
import { Link } from "react-router-dom"
import { times, sample, random, sumBy } from "lodash"

import { ResponsiveBar } from "@nivo/bar"
import { ResponsivePie } from "@nivo/pie"

import clients from "~/constants/clients"
import sales from "~/constants/sales"
import bookingDetails from "~/constants/bookingDetails"

import PageContainer from "~/library/PageContainer"

const performanceData = sales.map((s) => ({ name: s.user, sales: s.revenue }))

const salesByCategory = bookingDetails.reduce((acc, order) => {
  order.lineItems.forEach((item) => {
    const category = item.category
    const amount = item.price * item.quantity
    acc[category] = (acc[category] || 0) + amount
  })
  return acc
}, {})

// Convert the grouped data into the format Nivo expects
const chartData = Object.entries(salesByCategory).map(([category, value]) => ({
  id: category,
  label: category,
  value: value,
}))

const SalesReport = () => {
  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }

  return (
    <PageContainer>
      <div className="page-title-box d-flex align-items-center">
        <h1 className="page-title">Sales Report</h1>
        <div className="page-title-right m-auto me-0">
          <select className="form-select">
            <option>Month to Date</option>
            <option>Quarter to Date</option>
            <option>Last Month</option>
            <option>Last Quarter</option>
            <option>Custom Date</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="card text-center">
            <div className="card-body">
              <h5>Total Sales</h5>
              <h3>{formatCurrency(sumBy(sales, "revenue"))}</h3>
            </div>
          </div>
          <div className="card text-center mt-2">
            <div className="card-body">
              <h5>Average Booking Size</h5>
              <h3>{formatCurrency(131492)}</h3>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h5>Team Performance</h5>
              <div style={{ height: 300, width: "100%" }}>
                <ResponsiveBar
                  data={performanceData}
                  keys={["sales"]}
                  indexBy="name"
                  layout="vertical"
                  margin={{ top: 30, bottom: 30 }}
                  padding={0.3}
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  valueFormat={(v) => formatCurrency(v)}
                  colors={{ scheme: "set3" }}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: "middle",
                    legendOffset: 32,
                  }}
                  axisLeft={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h5>Sales by Category</h5>
              <div style={{ height: 300, width: "100%" }}>
                <ResponsivePie
                  data={chartData}
                  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  colors={{ scheme: "set3" }}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: "color" }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
                  valueFormat={(v) => formatCurrency(v)}
                  defs={[
                    {
                      id: "dots",
                      type: "patternDots",
                      background: "inherit",
                      color: "rgba(255, 255, 255, 0.3)",
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: "lines",
                      type: "patternLines",
                      background: "inherit",
                      color: "rgba(255, 255, 255, 0.3)",
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateX: 0,
                      translateY: 56,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: "#999",
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: "circle",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemTextColor: "#000",
                          },
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {sales.map((sale, i) => {
          return (
            <div className="col-12 p-2" key={i}>
              <h4 className="card-title fs-3 mb-4">
                <img
                  src={`https://avatar.iran.liara.run/username?username=${sale.user
                    .split(" ")
                    .join("+")}`}
                  className="rounded-circle avatar-xs me-2"
                />
                {sale.user}
              </h4>
              <div className="row">
                <div className="col-3 text-center">
                  <div className="card">
                    <h5>New Bookings</h5>
                    <h3>{sale.bookings}</h3>
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div className="card">
                    <h5>Total Sales</h5>
                    <h3>{formatCurrency(sale.revenue)}</h3>
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div className="card">
                    <h5>New Clients</h5>
                    <h3>{sale.newClients}</h3>
                  </div>
                </div>
                <div className="col-3 text-center">
                  <div className="card">
                    <h5>Repeat Clients</h5>
                    <h3>{sale.repeatClients}</h3>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Bookings</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Booking</th>
                            <th>Client</th>
                            <th>Booking Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {times(5, (i) => {
                            const client = sample(clients)
                            return (
                              <tr key={i}>
                                <td key="s">Suite {random(1, 10)}</td>
                                <td key="c">
                                  <Link to="/clients/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                                    <img
                                      src={`https://avatar.iran.liara.run/username?username=${client.name
                                        .split(" ")
                                        .join("+")}`}
                                      className="rounded-circle avatar-xs me-2"
                                    />
                                    {client.name}
                                  </Link>
                                </td>
                                <td key="r">{formatCurrency(random(100000, 350000))}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                      <div className="text-center">
                        <span className="btn btn-link">Load More Bookings</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </PageContainer>
  )
}

export default SalesReport
