const sales = [
  {
    user: "Aaron Miler",
    bookings: 15,
    revenue: 1843812,
    newClients: 6,
    repeatClients: 9,
  },
  {
    user: "Katie Anderson",
    bookings: 10,
    revenue: 1243500,
    newClients: 8,
    repeatClients: 2,
  },
  {
    user: "Kyle Chaney",
    bookings: 13,
    revenue: 1678900,
    newClients: 5,
    repeatClients: 8,
  },
  {
    user: "Vince Surdo",
    bookings: 12,
    revenue: 1543812,
    newClients: 11,
    repeatClients: 1,
  },
]

export default sales
