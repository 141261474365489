const activity = [
  {
    user: "Aaron Miler",
    text: "Booking created",
    type: "sale",
  },
  {
    user: "Vince Surdo",
    text: "Payment collected",
    type: "payment",
  },
  {
    user: "Vince Surdo",
    text: "Payment collected",
    type: "payment",
  },
  {
    user: "Aaron Miler",
    text: "Booking created",
    type: "sale",
  },
  {
    user: "Aaron Miler",
    text: "Booking created",
    type: "sale",
  },
  {
    user: "Aaron Miler",
    text: "Payment collected",
    type: "payment",
  },
  {
    user: "Kyle Chaney",
    text: "Booking created",
    type: "sale",
  },
  {
    user: "Katie Anderson",
    text: "Payment collected",
    type: "payment",
  },
  {
    user: "Aaron Miler",
    text: "Booking created",
    type: "sale",
  },
  {
    user: "Vince Surdo",
    text: "Payment collected",
    type: "payment",
  },
  {
    user: "Vince Surdo",
    text: "Payment collected",
    type: "payment",
  },
  {
    user: "Aaron Miler",
    text: "Booking created",
    type: "sale",
  },
  {
    user: "Aaron Miler",
    text: "Booking created",
    type: "sale",
  },
  {
    user: "Aaron Miler",
    text: "Payment collected",
    type: "payment",
  },
  {
    user: "Kyle Chaney",
    text: "Payment collected",
    type: "payment",
  },
  {
    user: "Katie Anderson",
    text: "Booking created",
    type: "sale",
  },
]

export default activity
