import React, { ReactNode } from "react"

interface CatchModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  children: ReactNode
  size?: "sm" | "lg" | "xl"
  centered?: boolean
  footer?: ReactNode
}

const CatchModal: React.FC<CatchModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  size,
  centered = true,
  footer,
}) => {
  if (!isOpen) return null

  const modalSizeClass = size ? `modal-${size}` : ""
  const modalDialogClass = `modal-dialog ${modalSizeClass} ${
    centered ? "modal-dialog-centered" : ""
  }`

  return (
    <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
      >
        <div className={modalDialogClass}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
            </div>
            <div className="modal-body">{children}</div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  )
}

export default CatchModal
