const pastDue = [
  {
    bookingName: "Summer Music Festival",
    client: "Hermes Conrad",
    balance: 250000, // $2,500.00
    daysPastDue: 30,
  },
  {
    bookingName: "Corporate Team Building Workshop",
    client: "Linda Belcher.",
    balance: 180000, // $1,800.00
    daysPastDue: 21,
  },
  {
    bookingName: "Wedding Photography Package",
    client: "Finn Mertains",
    balance: 75000, // $750.00
    daysPastDue: 14,
  },
  {
    bookingName: "Annual Charity Gala",
    client: "Oscar Piastri",
    balance: 500000, // $5,000.00
    daysPastDue: 7,
  },
  {
    bookingName: "Product Launch Event",
    client: "Steve Wilson",
    balance: 320000, // $3,200.00
    daysPastDue: 3,
  },
  {
    bookingName: "Birthday Party Package",
    client: "Mary Johnson",
    balance: 45000, // $450.00
    daysPastDue: 1,
  },
]

export default pastDue
