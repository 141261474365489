import * as React from "react"
import { Link, useParams } from "react-router-dom"
import moment from "moment"
import "react-calendar/dist/Calendar.css"

import PageContainer from "~/library/PageContainer"
import PageTitle from "~/library/PageTitle"
import CatchTable from "~/library/CatchTable"
import DateSelector from "~/library/DateSelector"

import getAppConfig from "~/utils/getAppConfig"
import useFetchResource from "~/utils/useFetchResource"
import { apiAreas } from "~/routes"

const AreaView = () => {
  const { id } = useParams()
  const { venueSlug } = getAppConfig()
  const endpoint = apiAreas.show.path({ venueSlug, id })
  const { state: area } = useFetchResource(endpoint, "area", {})

  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }

  const renderDetail = (title, content, size = 6) => {
    return (
      <div className={`col-${size || 6}`}>
        <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">{title}</p>
        <div className="d-flex">
          <div className="mt-1 font-14 mb-2">{content}</div>
        </div>
      </div>
    )
  }

  const bookingsTable = (bookings) => {
    return (
      <CatchTable
        data={bookings}
        columns={[
          {
            key: "name",
            header: "Booking",
            formatter: (name, data) => <Link to={`/bookings/${data.id}`}>{name}</Link>,
          },
          {
            key: "date",
            header: "Date",
            formatter: (date) => moment(date).format("MMM Do YYYY"),
          },
          {
            key: "clientName",
            header: "Client",
          },
          {
            key: "payments",
            header: "Payments",
            formatter: (payments) => formatCurrency(payments || 0),
          },
          {
            key: "anticipatedRevenueCents",
            header: "Anticipated Revenue",
            formatter: (antRev) => formatCurrency(antRev),
          },
        ]}
      />
    )
  }

  return (
    <PageContainer>
      <PageTitle title={area.name} newItemPath="/test" newItemText="New Booking" />
      <div className="row">
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Upcoming Bookings</h4>
              {bookingsTable(area.upcomingBookings)}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Historical Bookings</h4>
              {bookingsTable(area.historicalBookings)}
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Details</h4>
              <div className="row">
                {renderDetail("Total Capacity", area.capacity)}
                {renderDetail("Lifetime Bookings", area.bookingCount)}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Calendar</h4>
              <DateSelector name="date" isFormik={false} disabledDates={area.bookedDates} />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}
export default AreaView
