import * as React from "react"
import { Link } from "react-router-dom"
import moment from "moment"

import clients from "~/constants/clients"

import PageContainer from "~/library/PageContainer"

const ClientView = () => {
  const client = clients.find((c) => c.name === "Ron Swanson")

  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency / 100)
  }

  const renderDetail = (title, content, size = 6) => {
    return (
      <div className={`col-${size || 6}`}>
        <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">{title}</p>
        <div className="d-flex">
          <div className="mt-1 font-14 mb-2">{content}</div>
        </div>
      </div>
    )
  }

  return (
    <PageContainer>
      <div className="page-title-box d-flex align-items-center">
        <h1 className="page-title">Client</h1>
        <div className="page-title-right m-auto me-0" />
      </div>
      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-2">{client.name}</h4>
              <div className="row">
                {renderDetail("Email", client.email)}
                {renderDetail("Phone", client.phone)}
                {renderDetail("Last Booking", client.lastBooking)}
                {renderDetail("Total Bookings", client.bookings.length)}
                {renderDetail("Total Spend", formatCurrency(client.totalSpend))}
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="card-body">
              <h4 className="card-title mb-2">Bookings</h4>
              <table className="table table-centered table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Booking</th>
                    <th>Date</th>
                    <th>Area</th>
                    <th>Total Payments</th>
                  </tr>
                </thead>
                <tbody>
                  {client.bookings.map((booking, i) => {
                    return (
                      <tr key={i}>
                        <td key="l">
                          <Link to="/bookings/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                            {booking.bookingName}
                          </Link>
                        </td>
                        <td key="d">{moment(booking.date).format("MMM Do YYYY")}</td>
                        <td key="e">
                          <Link to="/areas/7cf331b3-3ee5-4b86-8b40-253de1e1a813">
                            {booking.area}
                          </Link>
                        </td>
                        <td key="p">{formatCurrency(booking.payments)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Notes</h4>
              {client.notes.map((note, i) => {
                return (
                  <div key={i} className="mt-3 bg-light p-2">
                    <p className="mb-1">{note.body}</p>
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <img
                          src={`https://avatar.iran.liara.run/username?username=${note.author
                            .split(" ")
                            .join("+")}`}
                          className="rounded-circle avatar-xs me-2"
                        />
                        {note.author}
                      </div>
                      <div className="d-flex ms-auto align-items-center">
                        {moment(note.date).format("MMM Do YYYY")}
                      </div>
                    </div>
                  </div>
                )
              })}

              <label className="form-label mt-3">Add New Note</label>
              <textarea placeholder="Add new note" className="form-control" />
              <div className="d-flex">
                <button disabled className="btn btn-primary ms-auto mt-2">
                  Add Note
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  )
}

export default ClientView
