// JsFromRoutes CacheKey 26108a1c1048aaa09126c9e6dd3282ca
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client"

export default {
  index: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/bookings"),
  create: /* #__PURE__ */ definePathHelper("post", "/api/:venue_slug/bookings"),
  show: /* #__PURE__ */ definePathHelper("get", "/api/:venue_slug/bookings/:id"),
}
