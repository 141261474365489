import { useState, useEffect } from "react"

interface FetchOptions {
  method?: "GET" | "POST" | "PUT" | "DELETE"
  headers?: HeadersInit
  body?: string
}

interface PaginationMetadata {
  currentPage?: number
  totalPages?: number
  totalItems?: number
  itemsPerPage?: number
  [key: string]: any
}

interface FetchState<T> {
  data: T | null
  isLoading: boolean
  error: Error | null
  metadata?: PaginationMetadata
}

interface UseFetchResource<T, K extends keyof T | undefined = undefined> {
  state: FetchState<K extends keyof T ? T[K] : T>
  metadata?: PaginationMetadata
  isLoading: boolean
  error: Error | null
  refetch: (params?: Record<string, string>) => Promise<void>
}

function useFetchResource<T extends object, K extends keyof T | undefined = undefined>(
  initialUrl: string,
  dataKey?: K,
  initialParams?: Record<string, string>,
  options: FetchOptions = {}
): UseFetchResource<T, K> {
  const [state, setState] = useState<FetchState<K extends keyof T ? T[K] : T>>({
    data: null,
    isLoading: true,
    error: null,
    metadata: undefined,
  })

  const buildUrl = (baseUrl: string, params?: Record<string, string>) => {
    if (!params) return baseUrl
    const searchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        searchParams.append(key, value)
      }
    })
    const queryString = searchParams.toString()
    return queryString ? `${baseUrl}?${queryString}` : baseUrl
  }

  const fetchData = async (params?: Record<string, string>) => {
    setState((prev) => ({ ...prev, error: null }))

    try {
      const url = buildUrl(initialUrl, params)
      const response = await fetch(url, {
        ...options,
        headers: {
          "Content-Type": "application/json",
          ...options.headers,
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const responseData: T = await response.json()

      // Extract metadata if it exists
      const metadata = "metadata" in responseData ? (responseData as any).metadata : undefined

      // Extract the specific data key if provided, otherwise use the whole response
      const extractedData = dataKey ? responseData[dataKey] : responseData

      setState({
        data: extractedData as K extends keyof T ? T[K] : T,
        isLoading: false,
        error: null,
        metadata,
      })
    } catch (error) {
      setState({
        data: null,
        isLoading: false,
        error: error instanceof Error ? error : new Error("An error occurred"),
        metadata: undefined,
      })
    }
  }

  useEffect(() => {
    fetchData(initialParams)
  }, [initialUrl]) // Only re-run if the base URL changes

  return {
    state: state.data || (initialUrl.endsWith("s") ? [] : {}),
    metadata: state.metadata,
    isLoading: state.isLoading,
    error: state.error,
    refetch: fetchData,
  }
}

export default useFetchResource
