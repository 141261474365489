import React, { useState, useEffect } from "react"
import { Link, Navigate } from "react-router-dom"

import getAppConfig from "~/utils/getAppConfig"
import useFetchResource from "~/utils/useFetchResource"
import { apiAreas, apiBookings } from "~/routes"

import PageContainer from "~/library/PageContainer"
import CatchCard from "~/library/CatchCard"

import * as Yup from "yup"
import FormikWrapper from "~/library/FormikWrapper"
import FormikInput from "~/library/FormikInput"
import FormikTextarea from "~/library/FormikTextarea"
import FormikSelect from "~/library/FormikSelect"
import CurrencyInput from "~/library/CurrencyInput"
import DateSelector from "~/library/DateSelector"

const bookingSchema = Yup.object({
  clientName: Yup.string().required("Client Name is Required"),
  clientEmail: Yup.string().email().required("Client Email is Required"),
  clientPhone: Yup.string().required("Client Phone is Required"),
  areaId: Yup.string().required("An area selection is required"),
  date: Yup.string().required("A booking date is required"),
  name: Yup.string().required("A booking name is required"),
  details: Yup.string(),
})

const BookingForm = () => {
  const { venueSlug } = getAppConfig()
  const [selectedArea, setSelectedArea] = useState(null)
  const [bookedDates, setBookedDates] = useState([])
  const [isBookedDatesLoading, setIsBookedDatesLoading] = useState(false)
  const [createdBookingId, setCreatedBookingId] = useState(null)

  const areaEndpoint = apiAreas.index.path({ venueSlug })
  const { state: areas } = useFetchResource(areaEndpoint, "areas", {})

  useEffect(() => {
    if (selectedArea) {
      setIsBookedDatesLoading(true)
      const req = apiAreas.availability({ venue_slug: "hood-view-park", id: selectedArea })
      req.then(({ dates }) => {
        setBookedDates(dates)
      })
      req.finally(() => setIsBookedDatesLoading(false))
    }
  }, [selectedArea])

  const handleSubmit = (formValues) => {
    const data = {
      venue_slug: "hood-view-park",
      booking: formValues,
    }

    const req = apiBookings.create({ data })
    req.then(({ booking }) => {
      setCreatedBookingId(booking.id)
    })
  }

  if (createdBookingId) {
    return <Navigate to={`/bookings/${createdBookingId}`} />
  }

  return (
    <PageContainer>
      <div className="row h-100">
        <div className="col-6 offset-3 align-center mt-4">
          <h3 className="mb-3">New Booking</h3>
          <FormikWrapper
            onSubmit={handleSubmit}
            validationSchema={bookingSchema}
            onValuesChange={({ areaId }) => setSelectedArea(areaId)}
            initialValues={{
              name: "",
              clientName: "",
              clientEmail: "",
              clientPhone: "",
              areaId: "",
              anticipatedRevenueCents: 0,
              date: null,
            }}
          >
            <CatchCard title="Client Details">
              <FormikInput
                name="clientName"
                label="Client Name"
                placeholder="Ron Swanson"
                required
              />
              <FormikInput
                name="clientEmail"
                label="Client Email"
                placeholder="ron@verygoodbuilding.com"
                required
              />
              <FormikInput
                name="clientPhone"
                label="Client Phone"
                placeholder="555 123-1234"
                required
              />
            </CatchCard>
            <CatchCard title="Area & Date">
              <div className="row">
                <div className="col-12">
                  <h4>Area</h4>
                  {areas.length > 0 && (
                    <FormikSelect
                      name="areaId"
                      placeholder="Select an Area"
                      required
                      options={areas.map((a) => ({ value: a.id, label: a.name }))}
                    />
                  )}
                </div>
                <div className="col-12 mt-2">
                  <h4>{areas.find((a) => a.id == selectedArea)?.name} Availability</h4>
                  <DateSelector
                    name="date"
                    isFormik={true}
                    disabledDates={bookedDates}
                    futureOnly={true}
                    disabled={!selectedArea && !isBookedDatesLoading}
                  />
                </div>
              </div>
            </CatchCard>
            <CatchCard title="Booking Details">
              <FormikInput
                name="name"
                label="Booking Name"
                placeholder="Woodworking Enthusiests Outing"
                required
              />

              <CurrencyInput
                name="anticipatedRevenueCents"
                label="Anticipated Revenue"
                placeholder="8,6753.09"
                currency="USD"
              />
              <FormikTextarea
                name="details"
                label="Additional Details"
                placeholder="Client likes scotch, hates computers"
              />
            </CatchCard>
            <div className="d-flex">
              <div className="ms-auto">
                <Link className="btn btn-outline-secondary me-2" to="/bookings">
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </FormikWrapper>
        </div>
      </div>
    </PageContainer>
  )
}

export default BookingForm
